import type { ProductInformation } from '@orus.eu/dimensions'
import { type ProtectionStatus } from '@orus.eu/protection'
import type { ParameterlessTranslationKey } from '@orus.eu/translations'
import { memo } from 'react'
import {
  Avatar,
  AvatarDecorative,
  Chip,
  RowButtonV2,
  useTranslate,
  useUiContext,
  type AvatarDecorativeProps,
  type ChipVariant,
  type CompoundIconName,
  type UiContext,
} from '../../../../index.js'

export type ContractProduct = {
  productInformation: ProductInformation
  productsAvatarConfigurations: Pick<AvatarDecorativeProps, 'icon' | 'backgroundColor'>
  currentProtectionStatus: ProtectionStatus | undefined
  contractNumber: string
  displayContractNumber: boolean
  onClick: () => void
}

export const ContractProduct = memo<ContractProduct>(function ContractProduct(props) {
  const translate = useTranslate()
  const screenType = useUiContext()

  const {
    productInformation,
    productsAvatarConfigurations,
    currentProtectionStatus,
    contractNumber,
    displayContractNumber,
    onClick,
  } = props

  return (
    <RowButtonV2
      avatarLeft={<AvatarDecorative size="50" {...productsAvatarConfigurations} />}
      avatarRight={
        screenType !== 'mobile' &&
        currentProtectionStatus &&
        currentProtectionStatus !== 'active' && (
          <Chip
            size="small"
            variant={getChipVariation(currentProtectionStatus)?.variant}
            avatar={<Avatar icon={getChipVariation(currentProtectionStatus)?.icon} />}
          >
            {translate(protectionStatusLabels[currentProtectionStatus])}
          </Chip>
        )
      }
      primaryText={productInformation.name}
      secondaryText={displayContractNumber ? translate('contract_n', { contractNumber }) : undefined}
      onClick={onClick}
    />
  )
})

export const CurrentProtectionStatusChip = memo<{
  currentProtectionStatus: ProtectionStatus
  screenType: UiContext
}>(function CurrentProtectionStatusChip({ currentProtectionStatus, screenType }) {
  const translate = useTranslate()
  if (screenType === 'mobile') return null
  return (
    currentProtectionStatus !== 'active' && (
      <Chip
        size={screenType === 'desktop' ? 'small' : 'large'}
        variant={getChipVariation(currentProtectionStatus)?.variant}
        avatar={<Avatar icon={getChipVariation(currentProtectionStatus)?.icon} />}
      >
        {translate(protectionStatusLabels[currentProtectionStatus])}
      </Chip>
    )
  )
})

function getChipVariation(
  currentProtectionStatus: ProtectionStatus,
): { variant: ChipVariant; icon: CompoundIconName } | undefined {
  switch (currentProtectionStatus) {
    case 'not-started':
      return { variant: 'informal', icon: 'forward-regular' }
    case 'active':
      return undefined
    case 'suspended':
      return { variant: 'warning', icon: 'circle-pause-regular' }
    case 'terminated':
      return { variant: 'danger', icon: 'heart-crack-regular' }
  }
}

const protectionStatusLabels: { [Status in ProtectionStatus]: ParameterlessTranslationKey } = {
  'not-started': 'contract_status_not_started', // 'Prochainement actif',
  active: 'contract_status_active', // 'Actif',
  suspended: 'contract_status_suspended', // 'Suspendu',
  terminated: 'contract_status_terminated', // 'Résilié',
}
